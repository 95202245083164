body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.container {
  max-width: 1280px !important;
}

iframe {
  display: none;
}

.link {
  text-decoration: underline;
  color: #007bff !important;
}
